/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : FrontEnd Team  
==============================================================================================*/

/* import bourbon - http://bourbon.io/docs */
@import "bourbon/bourbon";

@mixin rotation($attr){
     -ms-transform: rotate($attr); /* IE 9 */
    -webkit-transform: rotate($attr); /* Chrome, Safari, Opera */
    transform: rotate($attr);
}
@mixin appearance($attr){
    -webkit-appearance  : $attr;
   -moz-appearance      : $attr;
   appearance           : $attr;
}
@mixin transition($attr){
    transition: $attr;
    -webkit-transition: $attr;
    -moz-transition: $attr;
    -ms-transition: $attr;
    -o-transition: $attr;
}
@mixin transform($attr){
    transform: $attr;
    -webkit-transform: $attr;
    -moz-transform: $attr;
    -ms-transform: $attr;
    -o-transform: $attr;
}
@mixin borderRadius($attr){
    border-radius: $attr;
    -webkit-border-radius: $attr;
    -moz-border-radius: $attr;
    -ms-border-radius: $attr;
    -o-border-radius: $attr;
}
@mixin boxShadow($attr){
    box-shadow:  $attr;
    -webkit-box-shadow: $attr;
    -moz-box-shadow: $attr;
    -ms-box-shadow: $attr;
    -o-box-shadow: $attr
}
@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
}
@mixin boxSizing($attr){
    -webkit-box-sizing: $attr;
    -moz-box-sizing: $attr;
    box-sizing: $attr;
}

.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.before_clear:before{clear:both;display: block;content:" "}

$red : #891e13;
$red2 : #981b1f;
$white : #ffffff;
$grey : #cccccc;
$grey2 : #666666;
$grey3 : #7e7e7e;
$grey4 : #999999;
$grey5 : #e8e8e8;
$black : #000000;


.text-left {text-align: left !important}
.text-right {text-align: right !important}
.text-center {text-align: center !important}
.text-uppercase { text-transform: uppercase; }
.text-capitalize { text-transform: capitalize; }
.text-lowercase { text-transform: lowercase; }
.text-italic { font-style: italic; }
.text-bold { font-weight: bold; }
.text-light { font-weight: lighter; }
.text-regular { font-weight: lighter; }
.text-white { color: $white; }
.fl { float: left; }
.fr { float: right; }
.clear { clear: both; }
.inline { display: inline-block; }
