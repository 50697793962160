@import "sass-lib";

@media screen and(min-width:1px) and(max-width:767px){
	.hide-mobile { display: none; }
	body div.rect-big .content-right .thank-you, body div.rect-big-white .content-right .thank-you {
		.inner { padding: 0px 30px; 
			h4 { font-size: 20px; margin-bottom: 10px; margin-top: 10px; }
			h6 { font-size: 14px; line-height: 20px; }
		}
	}
	div.big-text-bg {
		 img { max-height: 45px; }
	}
	div.g-recaptcha { @include transform(scale(0.7)); transform-origin: 0; }
	div.breadcrumb-submenu {
		.wrapper {
			.left {
				width: 45%;
				span.mainPage { font-size: 12px; margin: 22px 0px; }
			}
			.right {
				width: 55%;
				span.currentPage { font-size: 12px; margin: 22px 0px; width: 126px; height: 13px; overflow:hidden; padding-right: 30px; }
				span.arrow { width: 10px; height: 7px; background-size: 100%; margin-left: 10px; position: absolute; top: 24px; right: 0px; }
			}
		}
	}
	div.col-2 { width: 100%; float: none; }
	div.downlist-container {
		div.downlist {
		    width: 100%; float: none; margin-bottom: 44px;
		    &:nth-child(odd) , &:nth-child(even) { padding:0px; }
		    div.content { padding-top:22px; }
		}
	}
	div.downlist2-container {
		.downlist2 {
		    float: none; width: 100%; padding: 36px 19px;
		    &:nth-child(odd) , &:nth-child(even) { margin:0px; margin-bottom: 30px; }

		}
	}
	div.pagination {
		ul {
			li {
				font-size: 13px !important;
				span.arrow-right, span.arrow-right-white, span.arrow-left, span.arrow-left-white { margin-left: 0px; }
			}
		}
	}
	p {
		&.text-title { top: 26px; font-size: 16px; 
			span.line { width: 20px; margin-left: 7px !important; }
		}
	}
	body {
		div.slider-2 {
			margin: 20px 0px;
			.list {
				img { height: 200px; max-width: 500%; }
				p.title { padding-top: 13px; font-size: 14px; padding-right: 82px; height: 30px; width: 100%; overflow: hidden; @include boxSizing(border-box); }
			}
			button {
				width: 40px; height: 40px; bottom: 22px;
				&.slick-prev { right: 39px; }
			}
		}
		div.slider-3 {
			div.list {
				.content {
					padding-top: 14px;
					p.title { font-size: 18px; margin-bottom: 0; }
					&::before { width:35px; height: 2px; }
				}
			}
		}
		div.slider-3-thumb {
			top: 32%;
			.list {
				padding: 0px 10px; @include boxSizing(border-box); border-right: 1px solid $grey;
			}
		}
		div.rect-big {
			padding: 60px 0px 160px 0px; background-size: auto 110%; width: 118%; left: -9%; right: -9%;
			h3 { font-size: 18px; line-height: 24px; }
			.content { width: 100%; @include boxSizing(border-box); padding: 0px 40px; }
			.content-right {
				form { padding: 29px 19px; 
					div.button { margin-top: 10px; 
						button { padding: 15px 42px; margin: 10px auto; }
						.col-2 { text-align: center !important; 
							&.text-right { text-align: center !important; }
						}
					}
				}
			}
			.map { padding: 0px 18px; height: 200px; bottom: -100px; }
		}
		div.rect-big-white {
			padding-bottom: 105px; position: relative; width: 109%; left: -4%; right: -4%; margin: 0px 0px 160px;
			div.abs-content { padding: 46px 33px 0px 33px; }
			div.content-right { position: absolute; padding: 0px 13px; }
		}
		div.list-gallery {
			h3 { margin-bottom: 30px; }
			.list { width: 100%; margin: 0px; padding-bottom: 20px; }
		}
		table {
			tr {
				th { line-height: 24px; }
			}
		}
		&.home , &:not(.home){
			header {
				.wrapper {
					.hamburger-container {
						width: 18px; height: 17px; top: 22px;
						span.hamburger , span.hamburger::before , span.hamburger::after { width: 18px; height: 3px; }
						span.hamburger {
							&::before { top:-7px; }
							&::after { top:7px; }
						}
					}
				}
				.bottom {
					div.wrapper { padding: 15px 0px; }
					.fl {
						img { height: 32px; }
					}
					div.fr {
						top: 16px;
						div.search-mobile-container { height: 60px;
							form { width: 65%; 
								input { font-size: 14px; margin-top: 11px; }
								button { top: 11px; }
							} 
							ul.lang-mobile {
								li {
									margin-top: 23px;
									a { font-size: 14px; }
								}
							}
						}
					}
					ul.menu {
						position: relative; padding-top: 60px;
						li { padding: 25px 0px; 
							a { font-size: 14px; }
							span.ctrl-dropdown-header { top: 23px; }
							ul {
								bottom: -24px;
								li { padding-left: 0px; 
									a { font-size: 13px; line-height: 18px; }
								}
							}
							&::after { left: 17px !important; right: auto !important; }
						}
						&::after { content:""; clear: both; display: block; }
					}
				}
				&::before { top: 65px; }
			}
		}
		&.home {
			h1 { font-size: 30px; line-height: 45px; }
			h3 { font-size: 26px; line-height: 28px; margin-bottom: 7px; }
			section {
				&.mainbanner {
					background-position: 15% !important;
					div.wrapper { padding-left: 15px; }
                                        .slick-slide{
                                            background-size:auto 100%  !important;background-position:  center !important;
                                        }
				}
				&.about {
					div.wrapper {
						padding-top: 90px;
					}
					div.image {
						@include boxSizing(border-box); padding: 0px 20px;
						img { width: 100%; }
					}
					div.content {
						p { margin-top: 49px; font-size: 14px; line-height: 24px; padding-bottom: 10px; }
					}
				}
				&.project {
					/*background-color: rgba(80, 2, 4, 0.88);*/;margin-bottom:30px;
					div.big-text-bg {
						top: 22px;
					}
					div.wrapper { width: 98%; }
					p.text-title { top: 60px; }
					div.slider-project { padding-top: 154px; 
						div.list { margin-top: 0px; padding-top: 0px; padding-bottom: 0px !important; 
							div.title-desc {
								margin-bottom: 35px; padding-left: 20px;
								span.border { width: 80px; height: 5px; }
								h2 { font-size: 35px; line-height: 35px; }
								p { display: none; }
							}
						}
					}
					div.slider-project-nav { margin-top: 20px; width: 60% !important; right: 0px; left: 0px; bottom: 20px; margin: 0 auto; }
					div.bg-content {
						height: auto; bottom: 100px; max-height: 400px;
					}
				}
				&.news {
					margin-bottom: 80px;
					p.text-title { top: 22px; }
					div.wrapper-side {
						width: 100%; margin-bottom: 89px;
						div.image { max-width: 100%; width: 100%; height: 180px; float: none; }
						div.content { width: 100%; max-width: 100%; float: none; padding: 30px 40px 0px; }
					}
					div.news-list-container {
						div.news-list { float: none; width: 100%; padding-bottom: 61px;
							p , h4 , a { padding: 0px 15px; }
							h4 { margin-bottom: 24px; }
							&:nth-child(3) { padding-bottom: 0px; } 
						}
					}
				}
				&.investor {
					margin-bottom: 129px;
					div.wrapper-2 { padding-top: 118px; padding-left: 10px; padding-right: 10px; @include boxSizing(border-box);}
					div.side-left , div.side-right {
						width: 100%; float: none; padding: 0px 0px 0px 0px !important; margin: 0px 0px;
					}
					div.side-left { margin-bottom: 75px; }
					div.side-right {
						div.day-datetime { margin-bottom: 15px; } 
					}
				}
				&.csr {
					overflow: hidden; margin-bottom: 130px;
					.wrapper-2 { padding-top: 102px;  }
					p , a { padding: 0px 10px; }
					p { font-size: 16px; line-height: 24px; }
					img {
						&.plant { max-width: auto; max-height: 280px; bottom: -28px; }
						&.path-1 { width: 150%; max-width: 200%; }
						&.path-2 { display: none; }
					}
				}
				&.career {
					background-size: auto 90%;
					p.text-title { top: 22px; }
					div.wrapper-2 { padding: 84px 20px 0px 10px }
				}
			}
		}
		&:not(.home){
			h3.desc { margin: 40px 0px 40px; font-size: 18px; line-height: 28px; }
			hr.grey { margin: 60px 0px 30px;}
			figure.struktur { display: none; }
			figure.struktur-mobile { display: block; }
			div.col-two {
				width: 100%;
				&:nth-child(odd) { margin-top:20px; }
				&.col-padding-left {
					padding-left: 0px;
				}
			}
			div.article-list {
				margin-bottom: 60px;
				div.list {
					.image { float: none; width: 100%; padding-right: 0px; }
					.desc { float: none; width: 100%; }
				}
			}
			div.manajemen-list {
				margin-top:50px;
				.list {
					.image {
						width: 100%; float: none; position: relative; margin-bottom: 36px; padding-right: 0px;
					}
					.desc { float: none; width: 100%; display: block; padding-top: 20px; padding-right: 20px; }
				}
			}
			div.project {
				padding-bottom: 0px;
			}
			div.recent-product-list {
				width: 100%; float: none; margin-top: 35px; margin-bottom: 50px;
				.image {
					height: auto;
					img { position: relative; height: auto; max-width: 100%; }
				}
				.content { margin-top: 25px; padding-left: 15px; padding-right: 15px; }
				&:last-child { margin-bottom: 0px; }
			}
			div.project-list, div.project-list-full, div.project-list-full-2 {
				width: 100%; float: none; height: 200px; margin-bottom: 20px;
				.innerText { font-size: 18px; }
			}
			div.project-list-2 {
				padding: 37px 0px 141px; margin-bottom: 100px;
				div.bg-red , div.bg-white { display: none; }
				.content .inner {
				    position: relative;
				    resize: vertical;
				    transform: translateY(0%);
				    padding-left: 20px;
				}
				div.image { padding-left: 0px; z-index: 10; position: absolute; bottom: -23%; }
				&.red {
					position: relative;
					&::before {
						background-color: rgba(80, 2, 4, 0.88); left: -9%; right: -9%; bottom: 0px; top: 0px; content: ""; position: absolute;
					}
				}
				&.white {
					position: relative; border: 1px solid $grey; left: -4%; right: -4%; width: 107%;
					.content .inner {
						padding-left: 39px;
					}
					div.image { left: 10px; right: 10px; }
				}
			}
			h4 { font-size: 20px; line-height: 20px; }
			section {
				position: relative; margin-right: 0px;
				&.banner {
					height: 180px; background-size: cover; background-position: center; top: 66px;
				}
				&.content {
					padding-top: 70px; top: 65px;
					div.aside-mobile {
						ul { width: 100%; border: none; padding: 27px 0px 27px; 
							li {
								position: relative;
								span.ctrl-dropdown { left: auto; right: 0px; top: 8px; bottom: auto; width: 6px; height: 11px; @include transition(all .4s ease-in-out);
									&.active { @include rotation(90deg); }
								}
								ul.child {
									position: relative; margin: 0px; padding: 0px; margin-top: 10px; margin-bottom: 10px; padding-top: 15px; padding-bottom: 5px;
									&::before { content:''; position: absolute; left: -9%; right: -9%; background-color: #f6f6f6; bottom: 0px; top: 0px; }
									li { padding-left: 15px; 
										span.ctrl-dropdown { right: 0px; }
										ul {
											margin-left: 0px !important;
										}
									}
								}
							}
						}
					}
					div.content { display: block; 
						&.hasdatatitle {
							table {
								thead { display: none; }
								tr {
									display: block; margin-bottom: 30px; border-top: 1px solid #ccc;
									th { display: none;}
									td { display: block; width: 100%; position: relative; padding-left: 45%; @include boxSizing(border-box);
										&::before { @include boxSizing(border-box); font-weight: bold; padding: 20px 10px; content:attr(data-title); color: #fff; position: absolute;top: 0px; left: 0px; width: 40%; bottom: 0px; background-color: $red; }
										&::after { content: ""; position: absolute; bottom: 0px; right: 0px; left: 40%; height: 1px; background-color: #ccc; }
										&.text-center {
											min-height: 80px; text-align: left !important;
											&::before { text-align: left; }
										}
									}
								}
							}
						}
						&.career {
							table {
								thead { display: none; }
								tr {
									border-top: 1px solid #ccc;	
									th { display: none; }
									td {
										display: inline-block; width: 50%; position: relative; @include boxSizing(border-box);
										&:first-child { width:100%; padding-bottom: 0px; font-weight: bold; }
										&:last-child { text-align: right; top: -12px; } 
									}
								}
							}
						}
					}
				}
				&.popup {
					overflow: scroll;
					.popup-content {
						transform: translateY(0%); top: 50px; overflow: scroll; margin-bottom: 100px;
						span.close { top: 15px; right: 15px; }
						.wrap {
							.image {
								position: relative; width: 100%; padding-right: 0px; float: none; top: 41px;
								img { position: relative; left: 0px; width: 100%;  }
							}
							.desc {
							    float: none;
							    width: 100%;
							    margin-top: 74px;
							    padding-top: 20px;
							    padding-left: 20px;
							    padding-right: 20px;
							    margin-bottom: 60px;
							}
						}
					}
				}
			}
			div.article-title {
				ul.breadcrumb {
					margin-bottom: 0px !important; display: none;
					li {
						margin: 0px; padding: 0px 20px;
						a { font-size: 14px; line-height: 14px; }
					}
				}
				h1 { font-size: 30px; line-height: 36px; margin: 17px 0px 25px; }
			}
			footer { position: relative; top: 66px; }
		}
	}
	footer {
		padding: 18px 0px 23px; display: block; top: 65px;
		div.logo {
			text-align: center; display: block; width: 100%; margin-bottom: 34px;
			img { height: 36px; margin: 0 auto; float: none; }
		}
		div.address { display: none; }
		div.menu-socmed {
			position: relative; max-width: 100%; float: none;
			div.container-mobile {
				b { display: inline; float: left; position: relative; top: -7px; }
				div.socmed { float: right; width: auto; margin-top: 0px;
					div:last-child { margin-right:0px; }
				}
				&::after { content: ''; clear: both; display: block; }
			}
			div.socmed { margin-bottom: 0px; 
				div { margin-right: 13px; }
			}
			ul.menu { display: none; }
		}
		p.copyright { margin-top: 25px; font-size: 12px;line-height: 18px; opacity: 0.5; text-align: center; }
	}
}